<template>
  <div class="video-holder">
    <div class="liveTitle">
      <div>
        {{ broadcast }}
      </div>
    </div>
    <video-player :options="videoOptions"/>
  </div>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "Livestream",
  components: {
    VideoPlayer
  },
  props: {
    broadcast: String,
  },
  data() {
    return {
      videoOptions: {
        autoplay: false,
        controls: true,
        sources: [
          {
            src:
            "https://content.jwplatform.com/videos/w2ExaOpA-axiid9N9.mp4",
            type: "video/mp4"
          }
        ]
      }
    };
  }
};

</script>

<style scoped>
.video-holder {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.liveTitle {
  z-index: 5;
  text-align: center;
  background-color: #ffffff;
  padding: 10px;
}

.liveTitle > div {
  background-color: #fedb00;
  border-radius: 50px;
  text-align: center;
  height: 10px;
  padding: 8px 10px 15px 10px;

  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);

          box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
0% {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
}

70% {
  -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
          box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
}

100% {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
}

@keyframes pulse {
0% {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
}

70% {
  -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
          box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
}

100% {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
}

.liveTitle > div > p{
  color: #000000
}

.liveTitle > div > p span{
  color: #000000
}



</style>
