<template>
  <div>
          <iframe src="https://preview-nciwryiy.jwpapp.com/" scrolling="auto" style="position:absolute;" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  name: 'JWPlayer',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
iframe {
  width: 80%;
  height: calc(80% - 50px);
  border: none;
}
</style>
