<template>
  <div class="icon">

    <div class="pic" >
      <img :src="require('@/assets/img/' +  img_src)">
    </div>

    <div class="title">
      <h2>{{ title }}</h2>
      <p>{{ description }}</p>
    </div>

  </div>
</template>

<script>
export default {
  name: 'EventIcon',
  props: {
    description: String,
    title: String,
    img_src: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.icon {
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  background-color: #ffffff;
  height: 300px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.icon:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}


.pic > img {
  width: 100%;
}

.title {
  margin: 10px 20px 25px 15px;
}

h2 {
  margin: 10px 0
}
</style>
