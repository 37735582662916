<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal"
      role="dialog"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
      >
      <div class="titleBar">
        <div class="title">
          <h2>{{ modalTitle }}</h2>
        </div>
        <img class="close" @click="close" src="@/assets/img/close.svg">
      </div>
      <!-- <component :is="dynamicComponent"></component> -->
      <Agenda @changeToContent="changeToContent" v-if="title=='Agenda'" />
      <JWPlayer v-else-if="title=='Content on Demand'" />
      <activity-links v-else-if="title=='Activities'" />
      <photo-booth v-else-if="title=='Photo Booth'" />
      <photo-gallery v-else-if="title=='Photo Gallery'" />
      <attendee-list v-else-if="title=='Attendee List'" />
      <agenda-replay  @changeToAgenda="changeToAgenda" v-bind:title=videoTitle v-bind:videoSrc=video v-else-if="title=='Agenda Replay'" />
      <no-content v-else />
    </div>
  </div>
</transition>
</template>

<script>
import Agenda from '@/components/Agenda.vue'
import NoContent from '@/components/NoContent.vue'
import JWPlayer from '@/components/JWPlayer.vue'
import PhotoBooth from '@/components/PhotoBooth.vue'
import PhotoGallery from '@/components/PhotoGallery.vue'
import AttendeeList from '@/components/AttendeeList.vue'
import AgendaReplay from '@/components/AgendaReplay.vue'
import ActivityLinks from '@/components/ActivityLinks.vue'

export default {
  name: 'modal',
  components: {
    Agenda,
    NoContent,
    JWPlayer,
    PhotoBooth,
    PhotoGallery,
    AttendeeList,
    AgendaReplay,
    ActivityLinks
  },
  props: {
    modalTitle: String
  },
  data() {
    return {
      title: '',
      video: '',
      videoTitle: ''
    }
  },
  watch: {
    modalTitle(newVal) {
      this.title = newVal
    }
  },
  methods: {
    close() {
      this.$emit('close');
      if(this.title=='Agenda Replay'){
        this.title = 'Agenda'
      }
    },
    changeToContent(videoSrc,videoTitle) {
      if (videoSrc!==undefined) {
        this.title = 'Agenda Replay'
        this.video = videoSrc
        this.videoTitle = videoTitle
      }
    },
    changeToAgenda() {
      this.title = 'Agenda'
    }
  }
};
</script>

<style scoped>

h2 {
  color: white;
  margin-left: 20px;
  margin-top: 10px;
  font-size: 1.5em;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal {
  background-image: url("/background.png"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  box-shadow: 2px 2px 20px 1px;
  width: 80%;
  height: 80%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.titleBar {
  position: absolute;
  width: 80%;
  height: 50px;
  background-color: #000000;
  z-index: 10;
}

/* .title {
position: absolute;
left: 40px;
top: 10px;
color: black;
font-size: 1.5em;
font-family: Lato, sans-serif;
font-weight: 900;
z-index: 10;
} */

.close {
  position: absolute;
  right: 5px;
  top: 3px;
  height: 40px;
  cursor: pointer;
  z-index: 10;
}



</style>
