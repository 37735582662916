<template>
  <div>
    <div class="agenda">
      <div style="height:60px;"></div>
      <div class="title">
        <h2>Schedule per day</h2>
      </div>
      <table class="GeneratedTable">
        <thead>
          <tr>
            <th><p><span>Time</span></p></th>
            <th><p><span>Daye One</span><br>Who we are</p></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><p><span>12:00 - 12:05</span></p></td>
            <td><p><span>MCCGLC Philosophy</span><br>Speaker: Matteo Console Camprini</p></td>
            <td><img @click="philosophy" class="cod-icon" src="@/assets/img/agenda-play.png"></td>
          </tr>
          <tr>
            <td><p><span>12:05 - 12:10</span></p></td>
            <td><p>Social Break</p></td>
            <td></td>
          </tr>
          <tr>
            <td><p><span>12:10 - 12:20</span></p></td>
            <td><p><span>MCCGLC 2020: Future Vision</span></p></td>
            <td><img @click="future" class="cod-icon" src="@/assets/img/agenda-play.png"></td>
          </tr>
          <tr>
            <td><p><span>12:20 - 12:50</span></p></td>
            <td><p>Exercise Break</p></td>
            <td></td>
          </tr>
          <tr>
            <td><p><span>12:50 - 13:50</span></p></td>
            <td><p><span>Workshop I</span></p></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div style="height: 50px;"></div>
      <table class="GeneratedTable">
        <thead>
          <tr>
            <th><p><span>Time</span></p></th>
            <th><p><span>Day Two</span><br>What we do</p></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><p><span>12:00 - 12:05</span></p></td>
            <td><p><span>SPACEIN Video</span><br>Speaker: All</p></td>
            <td><img @click="spacein" class="cod-icon" src="@/assets/img/agenda-play.png"></td>
          </tr>
          <tr>
            <td><p><span>12:05 - 12:10</span></p></td>
            <td><p>Social Break</p></td>
            <td></td>
          </tr>
          <tr>
            <td><p><span>12:00 - 12:05</span></p></td>
            <td><p><span>MCCGLC SmART Communications</span><br>Speaker: Emily Brearley-Baybliss , Hannah Tivey, Richard Dance, Stuart Norris & Lee Frangiamore</p></td>
            <td><img @click="smart" class="cod-icon" src="@/assets/img/agenda-play.png"></td>
          </tr>
          <tr>
            <td><p><span>12:10 - 12:20</span></p></td>
            <td><p><span>Ocean Photography Awards Case Study</span><br>Speaker: Mila Mutafchieva, Adam Taylor, Stuart Norris, Joe Newmarch</p></td>
            <td><img @click="opa" class="cod-icon" src="@/assets/img/agenda-play.png"></td>
          </tr>
          <tr>
            <td><p><span>12:20 - 12:50</span></p></td>
            <td><p>Exercise Break</p></td>
            <td></td>
          </tr>
          <tr>
            <td><p><span>12:50 - 13:50</span></p></td>
            <td><p><span>Workshop II</span></p></td>
            <td></td>
          </tr>
        </tbody>
      </table>

    </div>
    <div style="height:100px;"></div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    philosophy(){
      this.$emit('changeToContent', 'DgVmxBmI', 'Day 1 | MCCGLC Philosophy');
    },
    future() {
      this.$emit('changeToContent', '1JolVZGX', 'Day 1 | MCCGLC 2020:Future Vision');
    },
    spacein() {
      this.$emit('changeToContent', 'ipSd75kv', 'Day 2 | SPACEIN Video');
    },
    smart() {
      this.$emit('changeToContent', 'FgKXDmGZ', 'Day 2 | MCCGLC SmART Communications');
    },
    opa() {
      this.$emit('changeToContent', 'w2ExaOpA', 'Day 2 | Ocean Photography Awards Case Study');
    },
}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


h2 {
  font-size: 2em;
}

.agenda {
  padding: 0 70px;
  max-width: 1120px;
  margin: auto;
}

.cod-icon {
  margin-left: 50%;
  height: 40px;
  cursor: pointer;
}

.align-right {
  text-align: right;
}


table.GeneratedTable {
  width: 100%;
  background-color: #fedb31;
  border-collapse: collapse;
  border-width: 2px;
  border-color: #000000;
  border-style: solid;
  color: #000000;
}

table.GeneratedTable td, table.GeneratedTable th {
  border-width: 0px;
  border-color: #000000;
  border-style: solid;
  text-align: left;
  padding: 15px 10px;
}

table.GeneratedTable thead {
  background-color: #ffffff;
  border-width: 3px;
  border-color: #000000;
  border-style: solid;
  font-size: 1.2em;
}

table.GeneratedTable tr:nth-child(even) {
  background-color: #E2BF00;

}

thead tr th:first-child,
tbody tr td:first-child {
  width: 8em;
  min-width: 8em;
  max-width: 8em;
  word-break: break-all;
}

thead tr th:last-child,
tbody tr td:last-child {
  width: 8em;
  min-width: 8em;
  max-width: 8em;
  word-break: break-all;
}

</style>
