<template>
  <div>
    <div class="footer">
      <div>
    <h3>© MCCGLC Ltd 2021. All rights reserved.</h3>
  </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  position: relative;
  height: 100px;
  width: 100%;
}

.footer > div {
  text-align: center;
  padding-top: 50px;
}
</style>
