<template>
  <div>

    
    <Modal v-show="isModalVisible" @close="closeModal" :modalTitle="modalTitle"/>

    <div class="grid-container">

      <Livestream broadcast="LIVE: Ocean Photography Awards Case Study" class="div1"/>

      <minnit-chat class="div2"/>

      <div class="div3" @click="showModal('Agenda')">
          <event-icon title="Agenda" description="Explore the Schedule" img_src="agenda.png"/>
      </div>

      <div class="div4" @click="showModal('Content on Demand')">
          <event-icon title="Content on Demand" description="Watch sessions that have already happened here" img_src="cod.png" />
      </div>

      <div class="div5" @click="showModal('Activities')">
          <event-icon title="Links and activities" description="Take a break! Meditate, get some exercise or listen to a podcast" img_src="activitylinks.png"/>
      </div>

      <div class="div6" @click="showModal('Photo Booth')">
          <event-icon title="Photo Booth" description="Take your snapshot here with our custom frames and stickers" img_src="photobooth.png"/>
      </div>

      <div class="div7" @click="showModal('Photo Gallery')">
          <event-icon title="Gallery" description="Check out your teammates' photos!" img_src="photogallery.png"/>
      </div>

      <div class="div8" @click="showModal('Attendee List')">
          <event-icon title="Attendee List" description="See who is participating" img_src="attendees.png"/>
      </div>
    </div>
  </div>
</template>

<script>
import EventIcon from '@/components/EventIcon.vue'
import Livestream from "@/components/Livestream.vue";
import MinnitChat from "@/components/MinnitChat.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: 'EventContent',
  components: {
    Livestream,
    MinnitChat,
    EventIcon,
    Modal
  },
  data () {
    return {
      isModalVisible: false,
      modalTitle: "none",
    };
  },
  methods: {
    showModal(title) {
      this.isModalVisible = true;
      this.modalTitle = title
    },
    closeModal() {
      this.isModalVisible = false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



.grid-container {
  padding: 0px 70px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr;
  grid-template-rows: auto auto auto;
  gap: 20px 20px;
  grid-template-areas:
  "div1 div1 div1 div1 div1 div1 div2 div2"
  "div3 div3 div4 div4 div5 div5 div2 div2"
  "div6 div6 div7 div7 div8 div8 div2 div2";
}
.div2 { grid-area: div2; }
.div1 { grid-area: div1; }
.div3 { grid-area: div3; }
.div4 { grid-area: div4; }
.div5 { grid-area: div5; }
.div6 { grid-area: div6; }
.div7 { grid-area: div7; }
.div8 { grid-area: div8; }



@media all and (-ms-high-contrast:none) {

  .grid-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 2fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto 20px auto 20px auto;
    grid-template-rows: auto auto auto;
    gap: 20px 20px;
    grid-template-areas:
    "div1 div1 div1 div1 div1 div1 div2 div2"
    "div3 div3 div4 div4 div5 div5 div2 div2"
    "div6 div6 div7 div7 div8 div8 div2 div2";
  }
  .div2 { -ms-grid-row: 1; -ms-grid-row-span: 5; -ms-grid-column: 13; -ms-grid-column-span: 3; grid-area: div2; }
  .div1 { -ms-grid-row: 1; -ms-grid-column: 1; -ms-grid-column-span: 11; grid-area: div1; }
  .div3 { -ms-grid-row: 3; -ms-grid-column: 1; -ms-grid-column-span: 3; grid-area: div3; }
  .div4 { -ms-grid-row: 3; -ms-grid-column: 5; -ms-grid-column-span: 3; grid-area: div4; }
  .div5 { -ms-grid-row: 3; -ms-grid-column: 9; -ms-grid-column-span: 3; grid-area: div5; }
  .div6 { -ms-grid-row: 5; -ms-grid-column: 1; -ms-grid-column-span: 3; grid-area: div6; }
  .div7 { -ms-grid-row: 5; -ms-grid-column: 5; -ms-grid-column-span: 3; grid-area: div7; }
  .div8 { -ms-grid-row: 5; -ms-grid-column: 9; -ms-grid-column-span: 3; grid-area: div8; }

}
</style>
