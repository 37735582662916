<template>
  <div class="home">
    <Header title="EVENTIN"/>
    <EventContent />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import EventContent from '@/components/EventContent.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    EventContent,
    Footer
  }
}
</script>
