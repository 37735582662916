<template>
  <div>
    <div style="height:50px"></div>
          <iframe src="https://mccglc.xyz/spacein-content/spacein-photobooth.html" scrolling="auto" style="position:absolute;" allowfullscreen allow="camera;microphone" ></iframe>
  </div>
</template>

<script>
export default {
  name: 'Photo Booth',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
iframe {
  width: 80%;
  height: calc(80% - 50px);
  border: none;
}
</style>
