<template>
  <div>
          <iframe src="https://minnit.chat/eventindemo?embed&&nickname=" style="border:none;" allowTransparency="true"></iframe>
  </div>
</template>

<script>
export default {
  name: 'MinnitChat',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
iframe {
  width: 100%;
  height: calc(100vh - 350px);
  max-height: 1090px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
</style>
