<template>
  <div class="video-holder">
    <h2>{{ title }}</h2>
    <video-player :options="videoOptions"/>

    <div @click="change" class="return">
      Return to the Agenda
    </div>

  </div>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "agenda-replay",
  components: {
    VideoPlayer
  },
  props: {
    broadcast: String,
    videoSrc: String,
    title: String,
  },
  methods: {
    change() {
      this.$emit('changeToAgenda');
    }
  },
  data() {
    return {
      videoOptions: {
        poster: 'https://content.jwplatform.com/thumbs/' + this.videoSrc + '-1920.jpg',
        autoplay: false,
        controls: true,
        sources: [
          {
            src: 'https://content.jwplatform.com/videos/' + this.videoSrc + '-axiid9N9.mp4',
            type: "video/mp4"
          }
        ]
      }
    };
  }
};

</script>

<style scoped>
.video-holder {
  width: 80%;
  margin: auto;
  max-width: 1120px;
  margin: auto;
}

.return {
  margin: 20px 0 120px 0;
  cursor: pointer;
    text-align: right
}
</style>
