<template>
  <div id="list">
    <div>
      <h4> Registered Attendees </h4>
    </div>
    <div class="search">
    </div>
      <div class="list-holder">
        <ul>
          <li class="list-list" v-for="user in users" :key="user.name">
            <h2> {{ user.name }} </h2>
            <h3> {{ user.title }} | {{ user.country }} </h3>
          </li>
        </ul>
      </div>
  </div>
</template>

<script>
export default {
  name: 'attendee-list',
  data() {
    return{
      users: [
        { name:'ABELANT ZAHIRI', title:'Junior Developer', country:'Serbia' },
        { name:'ADAM ROGERS', title:'Designer', country:'UK' },
        { name:'ADAM TAYLOR', title:'Senior Graphic Designer', country:'UK' },
        { name:'ALICE DOUEIHI', title:'Senior Account Manager', country:'Lebanon' },
        { name:'AMY NIVEN', title:'Account Manager', country:'UK' },
        { name:'ANETE LIEPA', title:'Graphic Designer', country:'Latvia' },
        { name:'ANISHA MANDALI', title:'Financial Controller', country:'UK' },
        { name:'ANNIKEN HOLT', title:'Project Manager', country:'Norway' },
        { name:'BETH KARPOWICZ', title:'Chief Operating Officer', country:'UK' },
        { name:'CLEMENS WIIK', title:'Director of Project Operations', country:'Germany' },
        { name:'DANIEL FERNANDEZ', title:'Lead Development Designer', country:'Sweden' },
        { name:'DANIELE VOLTOLINA', title:'Motion Designer', country:'Italy' },
        { name:'DARIO TUNDO', title:'Production Manager', country:'Italy' },
        { name:'ELEANOR HUGHES', title:'Project Manager', country:'UK' },
        { name:'EMILY BREARLEY-BAYLISS', title:'Copywriter', country:'UK' },
        { name:'FREDERIC BERIRO', title:'Project Manager', country:'France' },
        { name:'GREGORIO III AGAWIN', title:'Graphic Designer', country:'Italy' },
        { name:'HANNAH PREECE', title:'Project Manager', country:'UK' },
        { name:'HANNAH TIVEY', title:'Scientific Director', country:'UK' },
        { name:'ISHTA NYAKOOJO', title:'Senior Account Manager', country:'UK' },
        { name:'JANKI GADHIA', title:'Financial Manager', country:'UK' },
        { name:'JOE NEWMARCH', title:'Head of Video & Broadcasting', country:'UK' },
        { name:'JORDAN TRICKETT', title:'Junior Developer', country:'UK' },
        { name:'JÖRG STRELLER', title:'Director of Client Relations', country:'Germany' },
        { name:'KATY RENWICK', title:'Marketing Director & Sustainability Lead', country:'UK' },
        { name:'LEE FRANGIAMORE', title:'Associate Creative Director', country:'UK' },
        { name:'MAKS ANDALA', title:'Senior Graphic Designer', country:'Poland' },
        { name:'MARIANA PALHA', title:'Head Of 2D', country:'Portugal' },
        { name:'MATTEO CONSOLE CAMPRINI', title:'Chief Executive Officer', country:'Italy' },
        { name:'MAYA COLWELL', title:'Sales Executive', country:'UK' },
        { name:'MILA MUTAFCHIEVA', title:'Account Manager', country:'Bulgaria' },
        { name:'MONIKA STEC', title:'Office Manager & Executive Assistant', country:'Canada' },
        { name:'OWEN DEWS', title:'Senior Designer', country:'UK' },
        { name:'RICCARDO MANIERO', title:'Junior Motion Designer', country:'Italy' },
        { name:'RICHARD DANCE', title:'Head of Creative Technology', country:'UK' },
        { name:'RISHI RAI', title:'Junior Motion Designer', country:'Nepal' },
        { name:'ROBERTO TENCONI', title:'IT Manager', country:'Italy' },
        { name:'ROBYN WILLIS', title:'Designer', country:'UK' },
        { name:'SADIE NORMAN', title:'Creative Technologist', country:'UK' },
        { name:'SANTA ZEMBAHA', title:'Senior Graphic Designer', country:'Latvia' },
        { name:'SARAH WHITE', title:'Project Manager', country:'Australia' },
        { name:'SIMON LIU', title:'Creative Technologist', country:'China' },
        { name:'STUART NORRIS', title:'Associate Creative Director', country:'UK' },
        { name:'TANYA MERADJI', title:'Account Director', country:'UK' },
        { name:'TRISHA PATEL', title:'Project Manager', country:'UK' },
        { name:'VALERIA ESTEVES', title:'Head of Production Management', country:'Russia' },
        { name:'VICTOR ZAPOROJAN', title:'Junior Front-End Developer', country:'Romania' },
        { name:'YENIL RODRIGUEZ FERRABEE', title:'Senior Copywriter', country:'Canada' },
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
  margin: 0;
}

h3 {
  margin: 0;
}

h4 {
  font-size: 2em;
}

#list {
  padding: 70px;
  max-width: 1120px;
  margin: auto;
}

ul li{
    float: left;
    width: 50%;
    margin: 2% 0;

}
ul{
    list-style-type: none;
    padding: 0;
}

.search {
  background-color: rgba(0,0,0, 0.1);
  width: 100%;
  height: 50px;
}

</style>
