<template>
  <div class="header">
    <div class="header-image">
      <img alt="Vue logo" src="../assets/logo.svg">
    </div>
    <div class="header-title">
      <h1>{{ title }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header{
  padding: 40px 70px;
}

.header-image > img{
  width: 50px;
}

.header-title {
  text-align: center;
}
</style>
