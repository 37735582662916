<template>
  <div><div style="height:50px"></div>
          <iframe src="https://app.brandmatic.co/gallery/mphoto " scrolling="auto" style="position:absolute;" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  name: 'Photo Gallery',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
iframe {
  width: 80%;
  height: calc(80% - 50px);
  border: none;
}
</style>
