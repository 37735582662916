<template>
  <div>
    <div class="links">
      <div>
        <video-player class="shaddow" :options="videoOptions"/>

        <h2>Scroll down to enjoy more activities</h2>
      </div>
      <div>
        <h2>Strava Club</h2>
        <p>Join the MCCGLC Strava club to log your walk, run or cycle and connect with colleagues around the world!</p>
        <div style="height:20px"></div>
        <button @click="openLink('https://www.strava.com/')" >MCCGLC STRAVA</button>
      </div>
      <div>
        <h2>Dance Workouts</h2>
        <p>Get energised with an amazing workout.</p>
        <div style="height:20px"></div>
        <button @click="openLink('https://youtu.be/ofddKYnkxTQ')" >Caleb Marshall | Dance Workout | Lizzo - Truth Hurts</button>
        <button @click="openLink('https://www.youtube.com/watch?v=zxbN_r3Xx-w')" >Caleb Marshall | Dance Workout | Lizzo - Good As Hell (feat. Ariana Grande)</button>
        <button @click="openLink('https://www.youtube.com/watch?v=o1x8crRKxQ4')" >Caleb Marshall | Dance Workout | Lady Gaga, Ariana Grande - Rain On Me</button>
        <button @click="openLink('https://www.youtube.com/watch?v=0VBJOwPYIns')" >Caleb Marshall | Dance Workout | Bebe Rexha - Baby, I'm Jealous (ft. Doja Cat)</button>
        <button @click="openLink('https://youtu.be/kUW8mNck1lk?t=38')" >Caleb Marshall | Dance Workout | Dua Lipa - Don't Start Now</button>
      </div>
      <div>
        <h2>Meditation</h2>
        <p>Take a moment to breathe with some guided meditation.</p>
        <div style="height:20px"></div>
        <button @click="openLink('https://youtu.be/5vI8Kh3-epg')" >Daily Calm | 10 Minute Mindfulness Meditation | Santosha</button>
        <button @click="openLink('https://www.youtube.com/watch?v=fkgzQsxdn9I')" >Daily Calm | 10 Minute Mindfulness Meditation | Symphony</button>
        <button @click="openLink('https://www.youtube.com/watch?v=Xl_B45DpMLU')" >Daily Calm | 10 Minute Mindfulness Meditation | Self Soothing</button>
        <button @click="openLink('https://youtu.be/YF_P1ZzYgjA')" >Daily Calm | 10 Minute Mindfulness Meditation | Forgetting Time</button>
      </div>
      <div style="height:100px"></div>
  </div>
</div>
</template>

<script>

import VideoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: 'links',
  components: {
    VideoPlayer
  },
  methods: {
    openLink(link){
      window.open(link);
    }
  },
  data() {
    return {
      videoOptions: {
        poster: 'https://content.jwplatform.com/thumbs/ipSd75kv-1920.jpg',
        autoplay: false,
        controls: true,
        sources: [
          {
            src: 'https://content.jwplatform.com/videos/ipSd75kv-axiid9N9.mp4',
            type: "video/mp4"
          }
        ]
      }
    };
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p {
  font-size: 1.2em;
}

h2 {
  font-size: 1.5em;
}


button {
  width: 100%;
  height: 70px;
  margin: 5px 0;
  font-size: 1em;
  background-color: #ffffff;
  color: black;
  font-weight: bold;
  border: 3px solid #000000;
  cursor: pointer;
  outline: none;
}

button:hover {
  background-color: #e7e7e7;
}

.links {
  position: relative;
  height: 100px;
  width: 80%;
  margin: auto;
  max-width: 1280px;
}

.links > div {
  text-align: center;
  padding-top: 100px;
}

.spacer {
  height: 100px;
}

.shaddow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
</style>
